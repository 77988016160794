import { useExperiments } from '@wix/yoshi-flow-editor';

export function useCSSPBStyle() {
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');

  return function ({ displayVar }: { displayVar: string }) {
    return cssPBEnabled ? { display: displayVar } : undefined;
  };
}
